import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import {
  ANALYTICS_INTERACTION_TYPES,
  ANALYTICS_VIEW_FEATURES
} from 'dpl/constants/analytics';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import LitterParentsVennImages from 'dpl/breeder_dashboard/components/LitterParentsVennImages';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import BreederProfileFollowButton from 'dpl/favorites/components/BreederProfileFollowButton';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';

import BreederPostCardMediaGrid from './BreederPostCardMediaGrid';
import LikeButton from './LikeButton';
import BreederPostCardMenu from './BreederPostCardMenu';
import { BreederPostCardPropTypes } from '../utils/propTypes';
import BreederPostCardLargeMediaGrid from './BreederPostCardLargeMediaGrid';

const VENN_IMAGE_PROPS = {
  width: '24px',
  height: '24px',
  className: 'dib flex-none',
  isBordered: true,
  isOverlapping: true,
  isSmall: true
};

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederPostCard({
  analytics,
  breederPost,
  className,
  isVerticalLayout,
  refetchBreederPost,
  showLitterTag
}) {
  const dispatch = useDispatch();
  const {
    isBreeder,
    isBuyer,
    isLoggedIn,
    value: currentUserValue
  } = useCurrentUser();

  const currentUserBreederProfileId =
    currentUserValue?.data?.breeder_profile_id;

  const {
    author: breederProfile,
    content,
    created_at: createdAtDate,
    id: breederPostId,
    like_count: numOfLikes,
    litter_tags: litterTags,
    media_files: gallery,
    type
  } = breederPost;

  const updateType =
    type === 'litter_update' ? 'Litter update' : 'Program update';

  const hasMediaFiles = gallery.length > 0;

  const commonAnalytics = {
    breeder_post_id: breederPostId,
    litter_id: litterTags?.[0]?.id,
    view: analytics.view,
    view_feature_location: analytics.view_feature_location
  };

  function onLitterClick(litterId) {
    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  return (
    <div
      className={classnames(
        'BreederPostCard bg-white br12 ba b--gray-300',
        className
      )}
      data-test-id={`breeder-post-card-${breederPostId}`}
    >
      <div className="relative pv4 ph4">
        <div className="flex items-center">
          <UserAvatar
            className="flex-none"
            user={{ ...breederProfile, id: breederProfile.user_id }}
            shape="round"
          />
          <div className="ml2">
            <p className="font-16 fw-medium">{breederProfile.display_name}</p>
            <div className="font-14 secondary">
              {updateType} on {createdAtDate}
              {(isBuyer || !isLoggedIn) && (
                <>
                  <span> • </span>
                  <BreederProfileFollowButton
                    analytics={{
                      ...commonAnalytics,
                      breeder_name: breederProfile.name,
                      interaction_type: ANALYTICS_INTERACTION_TYPES.FOLLOW,
                      view_feature: ANALYTICS_VIEW_FEATURES.FOLLOW_LINK
                    }}
                    className="underline stone-700"
                    breederBreedIds={breederProfile.breeder_breed_ids}
                    breederProfileId={breederProfile.id}
                  />
                </>
              )}
            </div>
          </div>
          {isBreeder && currentUserBreederProfileId === breederProfile.id && (
            <BreederPostCardMenu
              breederProfileId={breederProfile.id}
              breederPostId={breederPostId}
              onDeletePost={refetchBreederPost}
            />
          )}
        </div>
        {content && (
          <ClampedMultiLineText
            className="font-16 md:font-18 lh-body pre-wrap mt5"
            numOfLines={hasMediaFiles ? 3 : 8}
            lineHeight={IS_LT_MD ? 22.4 : 25.2}
            text={content}
            moreText="Read more"
            lessText="Show less"
            moreButtonClassName="stone-700"
            fadeToButton
          />
        )}
      </div>
      {hasMediaFiles &&
        (isVerticalLayout ? (
          <BreederPostCardLargeMediaGrid gallery={gallery} />
        ) : (
          <BreederPostCardMediaGrid gallery={gallery} />
        ))}
      <div
        className={classnames('flex flex-column gap-16 pb4 ph4', {
          pt3: hasMediaFiles,
          'flex-md-row justify-between': isVerticalLayout
        })}
      >
        <div className="flex items-center tertiary">
          <LikeButton
            analytics={commonAnalytics}
            numOfLikes={numOfLikes}
            postId={breederPostId}
            onLike={refetchBreederPost}
          />
        </div>
        {showLitterTag &&
          litterTags?.map((litter, idx) => (
            <ClickableDiv
              key={idx}
              className={classnames(
                'BreederPostCard__tag flex items-center br3 ba b--gray-300 ph3 pv3',
                {
                  'w-50-md w-40-lg': isVerticalLayout
                }
              )}
              data-test-id={`breeder-post-litter-${litter.id}-tag`}
              onClick={() => onLitterClick(litter.id)}
            >
              <LitterParentsVennImages
                litterParents={litter.parents}
                vennImageProps={VENN_IMAGE_PROPS}
              />
              <p className="ml2 font-16">{litter.name}&apos;s litter</p>
            </ClickableDiv>
          ))}
      </div>
    </div>
  );
}

BreederPostCard.propTypes = {
  analytics: PropTypes.shape({
    view: PropTypes.string,
    view_feature_location: PropTypes.string
  }),
  breederPost: PropTypes.shape(BreederPostCardPropTypes).isRequired,
  className: PropTypes.string,
  isVerticalLayout: PropTypes.bool,
  refetchBreederPost: PropTypes.func.isRequired,
  showLitterTag: PropTypes.bool
};

BreederPostCard.defaultProps = {
  analytics: {},
  className: null,
  isVerticalLayout: false,
  showLitterTag: false
};
