import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useReducer, useState } from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import MediaGridCarousel from 'dpl/components/MediaGrid/MediaGridCarousel';

const MAX_VISIBLE_THUMBNAIL_COUNT = 5;

function getViewMoreButtonLabel({ totalCount, visibleCount }) {
  if (totalCount - visibleCount === 1) {
    return '+1';
  }

  if (totalCount > 1 && totalCount > visibleCount) {
    return `+${totalCount - visibleCount}`;
  }

  return null;
}

export default function BreederPostCardLargeMediaGrid({ className, gallery }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [isCarouselOpen, toggleIsCarouselOpen] = useReducer(
    isOpen => !isOpen,
    false
  );

  const visibleThumbnails = gallery.slice(0, MAX_VISIBLE_THUMBNAIL_COUNT);

  const totalThumbnailCount = gallery.length;
  const visibleThumbnailCount = visibleThumbnails.length;

  const viewMoreButtonLabel = getViewMoreButtonLabel({
    totalCount: totalThumbnailCount,
    visibleCount: visibleThumbnailCount
  });

  function openMediaCarousel(slideIndex) {
    toggleIsCarouselOpen();
    setActiveSlideIndex(slideIndex);
  }

  function handleCarouselClose() {
    toggleIsCarouselOpen();
    setActiveSlideIndex(0);
  }

  function handleViewMoreClick() {
    if (gallery.length === 1) {
      openMediaCarousel(1);
    } else {
      openMediaCarousel(MAX_VISIBLE_THUMBNAIL_COUNT);
    }
  }

  return (
    <div
      className={classnames(
        'BreederPostCardLargeMediaGrid overflow-hidden relative',
        className
      )}
      data-test-id="breeder-post-card-large-media-grid"
      data-thumbnails-visible={totalThumbnailCount > 1}
    >
      {gallery.length > 0 && (
        <div
          className={`BreederPostCardLargeMediaGrid__gallery BreederPostCardLargeMediaGrid__gallery-${visibleThumbnails.length}`}
        >
          {visibleThumbnails.map((galleryThumbnail, index) => {
            const isVideo = galleryThumbnail.type === 'Video';
            return (
              <ClickableDiv
                key={galleryThumbnail.id}
                className="BreederPostCardLargeMediaGrid__gallery-thumbnail relative"
                onClick={() => openMediaCarousel(index)}
              >
                <SmartImage
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  crop
                  src={
                    isVideo
                      ? galleryThumbnail.thumbnail_url
                      : galleryThumbnail.url
                  }
                />
                {isVideo && (
                  <span className="pv4 ph4 bg-black-60 br-100 transform-center-all">
                    <Icon
                      width="32px"
                      height="32px"
                      name="fetch-play"
                      className="white"
                    />
                  </span>
                )}
              </ClickableDiv>
            );
          })}
        </div>
      )}
      {viewMoreButtonLabel && (
        <button
          type="button"
          className="BreederPostCardMediaGrid__gallery__view-more br-pill font-14 fw-medium white absolute bottom-0 right-0 mv2 mh2 pv2 ph4"
          onClick={handleViewMoreClick}
        >
          {viewMoreButtonLabel}
        </button>
      )}
      {isCarouselOpen && (
        <MediaGridCarousel
          mediaFiles={gallery}
          selectedMediaIdx={activeSlideIndex}
          onClose={handleCarouselClose}
        />
      )}
    </div>
  );
}

BreederPostCardLargeMediaGrid.propTypes = {
  className: PropTypes.string,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Photograph', 'Video']),
      id: PropTypes.number,
      caption: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

BreederPostCardLargeMediaGrid.defaultProps = {
  className: null
};
