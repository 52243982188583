import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useReducer, useState } from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import MediaGridCarousel from 'dpl/components/MediaGrid/MediaGridCarousel';

const MAX_VISIBLE_THUMBNAIL_COUNT = 2;

function getViewMoreButtonLabel({ totalCount, visibleCount }) {
  if (totalCount === 1 || totalCount - visibleCount === 1) {
    return '+1';
  }

  if (totalCount > 1 && totalCount > visibleCount) {
    return `+${totalCount - visibleCount}`;
  }

  return null;
}

export default function BreederPostCardMediaGrid({ className, gallery }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [isCarouselOpen, toggleIsCarouselOpen] = useReducer(
    isOpen => !isOpen,
    false
  );

  const [mainThumbnail, ...galleryThumbnails] = gallery;

  const visibleThumbnails = galleryThumbnails.slice(
    0,
    MAX_VISIBLE_THUMBNAIL_COUNT
  );

  const totalThumbnailCount = galleryThumbnails.length;
  const visibleThumbnailCount = visibleThumbnails.length;

  const viewMoreButtonLabel = getViewMoreButtonLabel({
    totalCount: totalThumbnailCount,
    visibleCount: visibleThumbnailCount
  });

  function openMediaCarousel(slideIndex) {
    toggleIsCarouselOpen();
    setActiveSlideIndex(slideIndex);
  }

  function handleCarouselClose() {
    toggleIsCarouselOpen();
    setActiveSlideIndex(0);
  }

  function handleViewMoreClick() {
    if (galleryThumbnails.length === 1) {
      openMediaCarousel(1);
    } else {
      openMediaCarousel(MAX_VISIBLE_THUMBNAIL_COUNT + 1);
    }
  }

  return (
    <div
      className={classnames(
        'BreederPostCardMediaGrid overflow-hidden relative',
        className
      )}
      data-test-id="breeder-post-card-media-grid"
      data-thumbnails-visible={totalThumbnailCount > 1}
    >
      <ClickableDiv
        className="BreederPostCardMediaGrid__main-thumbnail"
        onClick={() => openMediaCarousel(0)}
      >
        <SmartImage
          className="h-100 w-100 object-cover"
          loadingClass="bg-light-gray"
          crop
          src={
            mainThumbnail.type === 'Video'
              ? mainThumbnail.thumbnail_url
              : mainThumbnail.url
          }
        />
        {mainThumbnail.type === 'Video' && (
          <span className="pv4 ph4 bg-black-60 br-100 transform-center-all">
            <Icon
              width="24px"
              height="24px"
              name="fetch-play"
              className="white"
            />
          </span>
        )}
      </ClickableDiv>
      {galleryThumbnails.length > 1 && (
        <div className="BreederPostCardMediaGrid__gallery">
          {visibleThumbnails.map((galleryThumbnail, index) => {
            const isVideo = galleryThumbnail.type === 'Video';
            return (
              <ClickableDiv
                key={galleryThumbnail.id}
                className="BreederPostCardMediaGrid__gallery-thumbnail relative"
                onClick={() => openMediaCarousel(index + 1)}
              >
                <SmartImage
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  crop
                  src={
                    isVideo
                      ? galleryThumbnail.thumbnail_url
                      : galleryThumbnail.url
                  }
                />
                {isVideo && (
                  <span className="pv2 ph2 bg-black-60 br-100 absolute left-0 bottom-0 mv2 mh2">
                    <Icon
                      name="fetch-play"
                      width="20px"
                      height="20px"
                      className="white"
                    />
                  </span>
                )}
              </ClickableDiv>
            );
          })}
        </div>
      )}
      {viewMoreButtonLabel && (
        <button
          type="button"
          className="BreederPostCardMediaGrid__gallery__view-more br-pill font-14 fw-medium white absolute bottom-0 right-0 mv2 mh2 pv2 ph4"
          onClick={handleViewMoreClick}
        >
          {viewMoreButtonLabel}
        </button>
      )}
      {isCarouselOpen && (
        <MediaGridCarousel
          mediaFiles={gallery}
          selectedMediaIdx={activeSlideIndex}
          onClose={handleCarouselClose}
        />
      )}
    </div>
  );
}

BreederPostCardMediaGrid.propTypes = {
  className: PropTypes.string,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Photograph', 'Video']),
      id: PropTypes.number,
      caption: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

BreederPostCardMediaGrid.defaultProps = {
  className: null
};
